.logo-image {
    width: 60px;
    height: 60px;
    margin-right: 1rem;
}

.img-fluid {
    margin-top: 1rem;
}

.linkedin-image {
    width: 25px;
    padding-bottom: 4px;
}

.animated-text {
    margin-bottom: 3.5rem; /* Add a margin at the bottom to create space */
}
  
.animated-info {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    white-space: nowrap;    
}

.project-box, .testimonial-box {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin-top: 1rem;
  }

.project-image {
    width: 90%;
    margin-bottom: 1rem;
}
  
.container.section {
    margin-top: 3.5rem;
}

.tf-v1-widget iframe {
    width: 100%;
    height: 700px;
}

#contact h2 {
    margin-bottom: 1.5rem;
}

#project .col-lg-6 {
    transition: transform 0.2s ease; /* Adds a smooth transition effect */
}

#project .col-lg-6:hover {
    transform: scale(1.05); /* Enlarges the column by 5% */
}